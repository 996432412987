<template>
  <div class="x-checkbox-group">
    <h4>{{ label }}</h4>
    <XCheckbox
        v-for="(item, i) of items"
        :value="value ? value.includes(item.value) : false"
        :key="i"
        :label="item.text"
        @input="handleInput(item.value, $event)"/>
    <HelpButton :id="id"/>
  </div>
</template>

<script>
import XCheckbox from '@/components/basic/XCheckbox';
import HelpButton from "@/components/basic/HelpButton.vue";

export default {
  name: 'XCheckboxGroup',
  components: {XCheckbox,HelpButton},
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    label: String,
    items: Array,
    id: String,
  },
  methods: {
    handleInput(key, value) {
      let newValue = this.deepCopy(this.value);
      if (!newValue) newValue = [];
      if (value && !newValue.includes(key)) newValue.push(key);
      else {
        const index = newValue.findIndex(x => x === key);
        if (index >= 0) {
          newValue.splice(index, 1);
        }
      }
      this.$emit('input', newValue);
    },
  },
};
</script>

<style scoped>
.x-checkbox-group {
  display: flex;
}
.x-checkbox-group >>> .help-button {
  margin-top: 2px;
}
</style>